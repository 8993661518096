import { createStore, useStore, isString, isNumber, parseJson, SelectorFunction } from '@veraio/core';
import { getAllConfigurations } from 'services';
import { SettingStore } from 'interfaces';
import { isConfigured } from '../config';

const initState: SettingStore = {};

const settingsStore = createStore(initState);

/**
 * Initialize settings store with this method. It will perform requests to get all public settings from DS API
 *
 * @example
 *
 * initSettingsStore();
 */
export const initSettingsStore = async (): Promise<void> => {
  if (!isConfigured('initSettingsStore')) return;

  const [res, err] = await getAllConfigurations();
  if (err) return err;

  const items = res?.map((el) => [el.key, el.value]) ?? [[]];
  settingsStore.setState(Object.fromEntries(items));
};

/**
 * Retrieve a value of a specific property from the store\
 * If the provided key contains valid string it will return a boolean\
 * Otherwise it will return the default value
 *
 * @param key the name of the key inside the store
 * @param defaultValue default value if the key does not contain a valid value
 * @return boolean
 */
export const getBooleanSettings = (key: string, defaultValue: boolean): boolean => {
  if (!isConfigured('getBooleanSettings')) return defaultValue;

  const val = settingsStore.getState()?.[key];
  return isString(val) && val.length ? val === '1' : defaultValue;
};

/**
 * Retrieve a value of a specific property from the store\
 * If the provided key contains valid string it will return a parsed json\
 * Otherwise it will return the default value
 *
 * @param key the name of the key inside the store
 * @param defaultValue default value if the key does not contain a valid value
 * @return json
 */
export const getJsonSettings = (key: string, defaultValue: any): { [key: string]: any } => {
  if (!isConfigured('getJsonSettings')) return defaultValue;

  return parseJson(settingsStore.getState()?.[key] ?? '', defaultValue);
};

/**
 * Retrieve a value of a specific property from the store\
 * If the provided key contains valid string it will return a number\
 * Otherwise it will return the default value
 *
 * @param key the name of the key inside the store
 * @param defaultValue default value if the key does not contain a valid value
 * @return number
 */
export const getNumberSettings = (key: string, defaultValue: number): number => {
  if (!isConfigured('getNumberSettings')) return defaultValue;

  const val = Number(settingsStore.getState()?.[key]);
  return isNumber(val) ? val : defaultValue;
};

/**
 * React hook to use the store inside components with selector method for subscribe to changes for part of the state
 *
 * @param callback function that accept the hole state as argument and return a part of that state which need to be changed for a re-render
 * @returns {SettingStore} object with data for currencies
 * @see SettingStore
 * @example
 *
 * useSettings(state => state.STRIPE_COUNTRIES) // this component will re-render only when there is change inside STRIPE_COUNTRIES
 * useSettings(state => state.COINPAYMENTS_ACTIVE) // this component will re-render only when there is change inside COINPAYMENTS_ACTIVE
 */
export const useSettings = (callback: SelectorFunction<SettingStore>): SettingStore | null => {
  if (!isConfigured('useSettings')) return null;

  return useStore(settingsStore, callback);
};
