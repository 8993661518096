import { isNumber } from '@veraio/core';
import { round, sub, mul, div } from 'exact-math';
import { Deal } from '../interfaces';

export const trimZerosFromString = (string: string): string => string.replace(/\.?0+$/, '');

/**
 * Convert an amount of fiat to human readable format of 2 digits after decimal point
 *
 * @param amount fiat amount which is not human readable - 4, 1.4567
 * @example
 *
 * roundFiat(42.5) // 42.5
 * roundFiat(42.5123444) // 42.51
 */
export const roundFiat = (amount: number): number => (isNumber(amount) ? round(amount, -15) : 0);

/**
 * Convert an amount of crypto to human readable format of 4 digits after decimal point
 *
 * @param amount crypto amount which is not human readable - 4, 1.4567
 * @example
 *
 * roundCrypto(42.5) // 42.5
 * roundCrypto(42.5123444) // 42.5123
 */
export const roundCrypto = (amount: number): number => (isNumber(amount) ? round(amount, -15) : 0);

/**
 * Stringify an amount to human readable format. Round to the desired digit after decimal.\
 * Or round to the first non-zero digit.
 *
 * @param amount amount which is not human readable - 1.4567782892, 0.000000006
 * @param digitAfterDecimal how many digits after decimal to be displayed, default is 4
 * @example
 *
 * stringifyToFirstNonZeroDigit(42.5123876) // 42.5123
 * stringifyToFirstNonZeroDigit(0.1499, 2) // 0.14
 * stringifyToFirstNonZeroDigit(0.000000017512, 2) // 0.00000001
 */
export const stringifyToFirstNonZeroDigit = (amount: number, digitAfterDecimal = 4): string => {
  const stringAmount = trimZerosFromString(amount.toFixed(15).toString());

  let roundToDigit = digitAfterDecimal;
  if (parseInt(stringAmount, 10) === 0) {
    // Find with regex the first non zero digit, it can return null if there is no such
    // It is searching for .00001, and it will return the string with . in front, thats why we reduce the length with 1
    // If there is no match null - 1 will return NaN, thats why we have ?? 1, so it will return real value
    const firstNonZeroDigit = (stringAmount.match(/\.0+[1-9]/)?.[0]?.length ?? 1) - 1;
    roundToDigit = Math.max(firstNonZeroDigit, digitAfterDecimal);
  }

  return trimZerosFromString(round(amount, -roundToDigit).toFixed(roundToDigit));
};

/**
 * Internal calculate method to perform an action on multiple methods\
 * Every argument can be number, null or undefined based on if there is price of this type\
 * Deal can have only crypto price or only fiat price, so there can be totalFiatPrice: null or totalCryptoPrice: null\
 * If only one of the arguments is not number the method returns null
 *
 * @param method one of the methods exported by exact math
 * @param args an array of number | null | undefined
 * @return number or null if only one argument is not number
 */
export const calc = (method: (...arg: any[]) => number, ...args: (number | null | undefined)[]): number | null =>
  args.some((el) => !isNumber(el)) ? null : method(...args);

/**
 * Dealshaker API send every deal with 3 main properties - price, discount and percentRatio\
 * Based on those 3 main parameters we need to calculate - price (total, fiat and crypto), original prices (total, fiat and crypto)\
 * Those new properties are added to the deal object, prices can be equal to original prices if there is no discount
 *
 * @param deal deal object for specific deal coming from API
 * @example
 *
 * calculateDealPrice({ price: 100, percentRatio: 50, discount: 0, ... }) // { originalPriceFiat: 50, originalPriceCrypto: 50, price: null ... }
 * calculateDealPrice({ price: 100, percentRatio: 50, discount: 50, ... }) // { originalPriceFiat: 50, originalPriceCrypto: 50, price: 50, priceFiat: 25 ... }
 */
export const calculateDealPrice = (originalDeal: Deal): Deal => {
  const deal = { ...originalDeal };
  const { percentRatio, discount } = deal;
  const hasDiscount = discount > 0;

  deal.originalPrice = deal?.originalPrice ?? deal?.price;
  deal.originalPriceCrypto = roundCrypto(mul(div(percentRatio, 100), deal.originalPrice));
  deal.originalPriceFiat = roundFiat(mul(div(sub(100, percentRatio), 100), deal.originalPrice));
  deal.discountedPrice = hasDiscount ? mul(div(discount, 100), deal.originalPrice) : 0;
  deal.price = hasDiscount ? roundFiat(sub(deal.originalPrice, deal.discountedPrice)) : deal.originalPrice;
  deal.priceCrypto = hasDiscount ? roundCrypto(mul(div(percentRatio, 100), deal.price)) : deal.originalPriceCrypto;
  deal.priceFiat = hasDiscount ? roundFiat(mul(div(sub(100, percentRatio), 100), deal.price)) : deal.originalPriceFiat;

  return deal;
};
