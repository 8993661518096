import { invert } from 'lodash-es';
import { CRYPTO_CURRENCIES } from './currencies';

export const PAYMENT_METHOD = {
  CASH_ON_DELIVERY: 2,
  BANK_TRANSFER: 4,
  CASH_WALLET: 5,
  ONE_WALLET: 6,
  OESP_WALLET: 7,
  COIN_PAYMENTS: 8,
  STRIPE: 9,
};

export const PAYMENT_METHOD_INVERSE = invert(PAYMENT_METHOD);

export const PAYMENT_METHOD_SELECT = Object.entries(PAYMENT_METHOD).map(([label, value]) => ({ label, value }));

export const EXTERNAL_PAYMENT_PROVIDERS = {
  COIN_PAYMENTS: {
    NAME: 'USDT',
    CODE: 'USDT',
    PRECISION: 5,
  },
};

export const PAYMENT_METHODS_CURRENCIES = {
  [PAYMENT_METHOD.COIN_PAYMENTS]: EXTERNAL_PAYMENT_PROVIDERS.COIN_PAYMENTS.CODE,
  [PAYMENT_METHOD.ONE_WALLET]: CRYPTO_CURRENCIES.ONE.code,
  [PAYMENT_METHOD.OESP_WALLET]: CRYPTO_CURRENCIES.OESP.code,
};

export const EXTERNAL_PAYMENT_PROVIDER = {
  COINPAYMENTS_CURRENCY: 'COINPAYMENTS_CURRENCY',
  COINPAYMENTS_COUNTRIES: 'COINPAYMENTS_COUNTRIES',
  COINPAYMENTS_ACTIVE: 'COINPAYMENTS_ACTIVE',
  STRIPE_COUNTRIES: 'STRIPE_COUNTRIES',
  STRIPE_FEE_PERCENT: 'STRIPE_FEE_PERCENT',
  STRIPE_FIXED_FEE_AMOUNT: 'STRIPE_FIXED_FEE_AMOUNT',
  STRIPE_MINIMAL_AMOUNT: 'STRIPE_MINIMAL_AMOUNT',
  STRIPE_PAYMENT_METHOD_ACTIVE: 'STRIPE_PAYMENT_METHOD_ACTIVE',
};
